import { questionType } from "@/constant/pages/help"
import { HELP_PAGE_PATH } from "@/constant/route"
// 输入验证码登录弹窗
let notValidCountryDialog = false
export const usePaddleNotValidCountryDialog = () => {
  if (notValidCountryDialog) return

  let closeDialog = null

  const submit = () => {
    closeDialog && closeDialog()
    return navigateTo(`${unref(HELP_PAGE_PATH)}?anchor=form&question_type=${questionType.NotPaid}`)
  }

  useCommonNormalDialog({
    popperClass: "sign-in-or-sign-up-wrapper-dialog",
    close() {
      notValidCountryDialog = false
    },
    contentSlots({ close }) {
      closeDialog = close
      const com = resolveComponent("PagesPaymentPaddleNotValid")
      return <com callback={submit} />
    }
  })

  notValidCountryDialog = true
}
