<template>
  <CommonDialog
    v-model="comShowDialog"
    :dialog-style="dialogStyle"
    popper-class="payment-paddle_dialog"
    @close="
      () => {
        onClose(true)
      }
    "
  >
    <div id="content-wrapper" class="content-wrapper">
      <div id="paddel" class="paddel"></div>
      <div v-if="!current_plan" :key="priceKey" :class="['price', orderInfo.u_discount_price ? 'save-card-price' : '']">
        <div class="p-t">
          <div class="tip tip-f-t" style="margin-top: 0">{{ topTip }}</div>
          <div class="title">
            {{ orderInfo.name || paymentType }}
          </div>
          <div v-if="!isFreeTrial" class="title-time">
            {{ useTranslateI18n(`payment.paddleDialog.period`, [periodDate]) }}
          </div>
          <div
            v-if="isFreeTrial"
            class="title-time"
            v-html="
              useTranslateI18n(`landing.landingFreeTrial.t30`, [{ type: 'static', class: 'title-time-b' }, periodDate])
            "
          ></div>
          <div v-if="isFreeTrial" class="title-time">
            {{ useTranslateI18n(`landing.landingFreeTrial.t31`, [endDate]) }}
          </div>
          <div class="tip justify t-item">
            <div>{{ useTranslateI18n("payment.account") }}</div>
            <div class="tip-r-email">{{ userInfo_email || "-" }}</div>
          </div>
          <div v-if="!isFeature && !isFreeTrial" class="tip justify t-item">
            <div class="tip-l">
              {{ useTranslateI18n(`payment.paddleDialog.credits`, [useNumFormat(props.orderInfo.credits)]) }}
            </div>
            <div class="tip-r">
              <!-- 折扣单价 -->
              <span v-if="priceDetail.discount || priceDetail.top_up_discount" class="tip-r-per">
                {{ priceDetail.perMoney }}
              </span>

              <!-- 原单价 -->
              <span v-if="!priceDetail.discount && !priceDetail.top_up_discount" class="tip-r-per">
                {{ orderInfo.singlePrice }}
              </span>
              <span class="tip-r-unit">{{ unit }}</span>
            </div>
          </div>

          <!-- referral bonus -->
          <div v-if="referral_code" class="referral-bonus">
            {{ useTranslateI18n(`referral.payment.bonus`, [useNumFormat(referral_bonus)]) }}
          </div>

          <!-- 原总价 -->
          <div class="tip justify t-item">
            <div>
              {{ useTranslateI18n(`payment.paddleDialog.total`) }}
              <span v-if="isFreeTrial">
                ({{ useTranslateI18n("landing.landingFreeTrial.t29", [orderInfo.no_price]) }})
              </span>
            </div>
            <div class="tip-tt">
              {{ isPaymentType ? priceDetail.total : orderInfo.price }}
            </div>
          </div>

          <!-- 补充包优惠多少 -->
          <div v-if="priceDetail.top_up_discount" class="tip justify t-item">
            <div>
              {{ useTranslateI18n("payment.comboSub.t95") }}
            </div>
            <div class="tip-tt tip-tt-num">-{{ priceDetail.top_up_discount }}</div>
          </div>

          <!-- 优惠多少 -->
          <div v-if="priceDetail.discount" class="tip justify save-sub-discount">
            <div class="total-c">
              <CloseFl />
              <span class="total-c-co">
                {{ coupon_code }}
              </span>
            </div>
            <div class="one-c">
              <span class="num">-{{ priceDetail.discount }}</span>
            </div>
          </div>

          <!-- 现总价 -->
          <div v-if="priceDetail.discount || priceDetail.top_up_discount" class="tip-sub tip justify t-item">
            <div class="tip-tt-s">
              {{ useTranslateI18n(`payment.paddleDialog.total1`) }}
              <span v-if="isFreeTrial">
                ({{ useTranslateI18n("landing.landingFreeTrial.t29", [orderInfo.no_price]) }})
              </span>
            </div>
            <div class="tip-tt">
              {{ priceDetail.total }}
            </div>
          </div>

          <div v-if="vatValue" class="flexEnd van t-item">
            <div>{{ vatValue }}</div>
          </div>
          <!-- <div v-if="refundVatValue" class="flexEnd van">
            <div>{{ refundVatValue }}</div>
          </div> -->
          <div class="line"></div>

          <div v-if="showAfterSubDesc && !isFreeTrial" class="save-sub-desc">
            <div class="save-sub-desc-date">{{ useTranslateI18n("payment.comboSub.t52", [endDate]) }}</div>
            <div class="save-sub-desc-price">
              {{ orderInfo.price }}
            </div>
          </div>

          <div v-if="showAfterSubDesc && !isFreeTrial" class="line"></div>

          <!-- <div v-if="refundResultValue" class="tip justify">
            <div>{{ useTranslateI18n(`payment.paddleDialog.total`) }}</div>
            <div style="color: rgba(255, 107, 0, 1); font-weight: bold; font-size: 16px">{{ refundResultValue }}</div>
          </div> -->
          <template v-if="showBeforeDesc && !showAfterSubDesc && !isFreeTrial">
            <div v-if="showBottomTip" class="bottom-tip">{{ useTranslateI18n(`payment.paddleDialog.cre`) }}</div>
            <div v-if="showBottomTip" class="bottom-tip">{{ useTranslateI18n(`payment.paddleDialog.unUsed`) }}</div>
            <div v-if="Number(orderInfo.credits) >= 9000" class="bottom-tip-bl">
              {{ useTranslateI18n(`payment.paddleDialog.tip1`) }}
            </div>
            <div v-if="Number(orderInfo.credits) >= 9000" class="bottom-tip-bl">
              {{ useTranslateI18n(`payment.paddleDialog.tip2`) }}
            </div>
          </template>
          <template v-if="showAfterSubDesc && !isFreeTrial">
            <div class="sub-list">
              <div v-for="(item, i) in comboSubDescList" :key="i" class="sub-list-item">
                <div class="item-t" v-html="item"></div>
              </div>
            </div>
          </template>
        </div>
        <div v-if="!isFreeTrial" class="bottom-tip-w">
          <div
            class="bottom-tip-w-t"
            v-html="
              useTranslateI18n(`payment.paddleDialog.tip4`, [
                {
                  type: 'static',
                  class: 'paddleDialog-to_details'
                }
              ])
            "
          ></div>
        </div>
      </div>
      <div v-if="current_plan" :key="priceKey" class="price">
        <RenderCom
          :show-close-icon="false"
          :coupon_code="coupon_code"
          :current_plan="current_plan"
          :compare_state="compare_state"
          :price-detail="priceDetail"
          :next_plan="orderInfo"
        />
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { computed, ref } from "vue"
import { getPassThrough, getNoLoginPassThrough, IPaymentLogData, PaymentMethod } from "~/composables/api/payment"
import { useUser } from "~/store/user"
import type { CSSProperties } from "vue"
import { IOfficialPricingPurchaseOperate } from "@/composables/utils/gtag/payment"
import { getLocalDateString } from "@/utils/date"
import { useClientPage } from "@/composables/useCommonComboPayment"
import { LANDING_COMBO_BF } from "@/constant/route"
import { useTranslateI18n } from "~~/composables/store/i18n-country"
import { useHelpStore } from "~~/store/help"
import { usePurchaseReferer } from "@/composables/store/referrer"
import { SessStorageKey } from "~~/constant/cache"
import CloseFl from "~~/assets/icons/common/payment-c-icon.svg"
import { ICardDetailData } from "@/types/components/pages/combo/comboCardT"
import PagesComboSubUpgradeNoCard from "@/components/pages/comboSub/SubUpgradeNoCard.vue"

interface IProps {
  referral_bonus?: number
  referral_code?: string
  current_plan?: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
  compare_state?: INewCompareState
  coupon_code?: string
  subPerTotal?: string
  subTotal?: string
  discount_price?: string
  discount_percentage?: string | number
  trade_no?: string
  checkout_url?: string
  isPassThrough?: boolean
  isRedeem: boolean
  isBackUnit: boolean | string // 有值单位就是放后面
  uuid?: string // 记录落地页购买次数需要
  char?: string
  orderInfo: any
  showDialog: boolean
  topTip?: string
  unit?: string
  showBottomTip?: boolean
  isPointer?: boolean
  paddleNoLoginPassThroughUUid?: string
  landingBfConfig?: {
    gift_card_receiver: string
    sender_name: string
  }
  showBeforeDesc?: boolean
  showAfterSubDesc?: boolean
  top_up_discount_price?: string
  top_up_discount_percentage?: string
  paymentSuccess?: (...args: any[]) => any
  paymentFail?: (...args: any[]) => any
  onClose?: (...args: any[]) => any
}
const props = withDefaults(defineProps<IProps>(), {
  referral_bonus: 0,
  referral_code: "",
  isRedeem: false,
  orderInfo: null,
  current_plan: null,
  compare_state: null,
  showDialog: false,
  char: () => {
    return "$"
  },
  topTip: () => {
    return useTranslateI18n(`payment.paddleDialog.sub`)
  },
  unit: () => {
    return useTranslateI18n(`payment.paddleDialog.mo`)
  },
  showBottomTip: () => {
    return true
  },
  isPointer: () => {
    // 是否发送埋点
    return true
  },
  isBackUnit: () => {
    return false
  },
  paddleNoLoginPassThroughUUid: () => {
    // 是否不登录获取paddle pass—through，自定义链接不登录
    return ""
  },
  landingBfConfig: () => {
    return {} as any
  },
  showBeforeDesc: () => {
    return true
  },
  showAfterSubDesc: () => {
    return false
  },
  isPassThrough: () => {
    return true
  },
  checkout_url: () => {
    return ""
  },
  trade_no: () => {
    return ""
  },
  isNewSub: () => {
    return false
  },
  discount_price: () => {
    return ""
  },
  subTotal: () => {
    return ""
  },
  subPerTotal: () => "",
  discount_percentage: () => "",
  top_up_discount_price: () => "",
  top_up_discount_percentage: () => "",
  coupon_code: () => ""
})

const vatConfig = reactive({
  tax: "",
  tax_percent: "",
  total: "",
  taxAll: ""
})

const RenderCom = (
  props: {
    current_plan: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
    next_plan: (ICardDetailData & IComboProductListObj) | (ICardDetailData & INewComboProductListObj)
    compare_state: INewCompareState
    discountValue: string
    priceDetail: typeof priceDetail
  },
  ctx
) => {
  let comDict = {
    [INewCompareState.Upgrade]: PagesComboSubUpgradeNoCard
  }
  let com = comDict[props.compare_state as INewCompareState]
  return com ? <com {...props} /> : null
}

const { comboSubDescList, executeSubDescListEvent } = useComboSubListenDom()

const vatValue = ref()

const refundVatValue = ref()

const refundResultValue = ref()

let priceKey = ref(1)

let { log } = useLogger()

const use_user = useUser()

let { usePaymentGTag } = useGTag()
let { _PointOfficePricingPurchase } = usePaymentGTag()

let { usePaymentMP } = useMp()
let { _pointMpOfficialPricingButtonClick } = usePaymentMP()
// 是否在feature页
const isFeature = computed(() => {
  return useFeaturePage("")
})

const isFreeTrial = computed(() => {
  return props.orderInfo.trial_credits
})

function listenDom() {
  let help = useHelpStore()
  let link = help.helpState.docLink
  let goHelpDom = document.querySelector(".paddleDialog-to_details")

  goHelpDom &&
    goHelpDom.addEventListener(
      "click",
      () => {
        // 判断是否是客户端内打开的，需要通知客户端跳外链
        const { $bridge } = useNuxtApp()
        const { isInlineClient } = useClientBridgeRouteGuard()
        if (isInlineClient) {
          $bridge.ipcRenderer.send("open-external-link", {
            href: link
          })
        } else {
          _pointMpOfficialPricingButtonClick({
            pageTitle: "pricingPage",
            buttonName: "clickPurchasePopHelp",
            featureModule: "officialPricing",
            featureName: "clickPricingPageButton"
          })
          location.href = link
        }
      },
      { passive: true }
    )

  executeSubDescListEvent()
}

watch(
  () => priceKey.value,
  () => {
    nextTick(listenDom)
  }
)

log("paddle orderInfo:", props.orderInfo)

const comShowDialog = computed(() => unref(props.showDialog))

let dialogStyle = reactive<CSSProperties>({
  backgroundColor: "#fff",
  padding: "40px"
})

// 记录总价格和优惠额度
let priceDetail = reactive({
  total: props.subTotal || props.orderInfo.u_discount_price,
  perMoney: props.subPerTotal || props.orderInfo.u_discount_per_credit_price,
  discount: props.discount_price,
  percentage: props.discount_percentage,
  top_up_discount: props.top_up_discount_price,
  top_up_percentage: props.top_up_discount_percentage
})

// 实际单位 优惠卷会改变单位
let trueChar = ref(props.char)

// 支付方式单位 jpy usd
let currency = ref("")

// 订单号
let _trade_no = ref(props.trade_no)

//支付类型
const paymentType = computed(() =>
  props.orderInfo.payment_type === 2
    ? useTranslateI18n(`payment.paddleDialog.pPlanY`)
    : useTranslateI18n(`payment.paddleDialog.pPlanM`)
)

// priceDetail.perMoney 字段的算法，如果是订阅的payment_type，priceDetail.perMoney不改变如果是套餐的购买算的是每张多少钱
const isPaymentType = computed(() => {
  return props.orderInfo.payment_type
})

const emit = defineEmits(["onClose", "paymentSuccess", "paymentFail"])
const onClose = (flag = false) => {
  if (flag && props.isPointer) {
    _PointOfficePricingPurchase({
      official_operate: IOfficialPricingPurchaseOperate.click_pay_close
    })
  }
  if (flag) {
    _pointMpOfficialPricingButtonClick({
      buttonName: "closePricingPage",
      pageTitle: "pricingPage",
      featureModule: "officialPricing",
      featureName: "clickPricingPageButton"
    })
  }
  props.onClose && props.onClose()
  emit("onClose")
}

//加载失败
const loadPageFail = (message) => {
  useErrorMessage({
    message: message && message.toString(),
    duration: 3000
  })
}

const product_id = ref(props.orderInfo.paddle_id)

const userInfo_email = ref(props.orderInfo.email || use_user.userInfo.email)
//paddel事件回调 优惠卷的金额增加
const frameInitialHeight = ref(430)

const getThrough = async () => {
  const { isClientInlinePage } = useClientPage()
  const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
  try {
    // 不登录获取信息
    if (props.paddleNoLoginPassThroughUUid) {
      const res = await getNoLoginPassThrough({
        uuid: props.paddleNoLoginPassThroughUUid,
        plan_id: props.orderInfo.paddle_id,
        ...(usePostToServerByPurchase() || {}),
        purchase_uuid: usePurchaseReferer(),
        purchase_source: useRefWebRefferer(),
        ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
      })
      _trade_no.value = res.trade_no
      return res.passthrough
    } else {
      let passThroughFn = props.isRedeem ? getRedeemPassthrough : getPassThrough
      const res = await passThroughFn({
        // 如果是黑五秒杀的就传true 为了判断哪个订单去赠送额外张数
        is_sec_kill_activity: useRoute().path === unref(LANDING_COMBO_BF),
        // 黑五单个套餐用户信息
        gift_card_receiver: props.landingBfConfig.gift_card_receiver,
        sender_name: props.landingBfConfig.sender_name,
        plan_id: props.orderInfo.paddle_id,
        uuid: props.uuid,
        ...(usePostToServerByPurchase() || {}),
        source: EVOTO_WEBSITE_TO_SERVER_PARAMS()[unref(isClientInlinePage) ? "evoto_DT_purchasePage" : useRoute().path], // source为了服务端上报购买成功事件，并且区分购买的来源。
        purchase_uuid: usePurchaseReferer(),
        purchase_source: useRefWebRefferer(),
        ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
      })
      _trade_no.value = res.trade_no
      return res.passthrough
    }
  } catch (e) {
    return false
  }
}

const successCallback = (params) => {
  props.paymentSuccess &&
    props.paymentSuccess({
      credits: props.orderInfo.credits,
      value: priceDetail.total || props.orderInfo.price,
      char: unref(isPaymentType) ? trueChar.value : props.orderInfo.currency_char,
      currency: unref(isPaymentType) ? currency.value : props.orderInfo.currency,
      trade_no: unref(_trade_no)
    })
  emit("paymentSuccess", {
    credits: props.orderInfo.credits,
    value: priceDetail.total || props.orderInfo.price,
    char: unref(isPaymentType) ? trueChar.value : props.orderInfo.currency_char,
    currency: unref(isPaymentType) ? currency.value : props.orderInfo.currency,
    trade_no: unref(_trade_no)
  })
  onClose()
}

const closeCallback = (event) => {
  props.paymentFail && props.paymentFail(event)
  emit("paymentFail", event)
  onClose()
}

let backList = [ICurrency.JPY, ICurrency.EUR]
let vatList = [ICurrency.EUR, ICurrency.GBP]
let gstList = [ICurrency.AUD]

// currency置后
const isBackCurrency = (currency) => {
  return backList.includes(currency)
}

const eventCallback = (data) => {
  // log
  if (process.env.NUXT_APP_ENV !== "production") {
    console.log(`paddle callback log:`, data)
  }

  let customer = data?.eventData?.checkout?.prices?.customer

  if (!customer) {
    return
  }

  let _char = ICurrencyUnit[customer?.currency] || ""

  let _isBackUnit = isBackCurrency(customer?.currency)

  vatConfig.total = customer?.total || 0

  vatConfig.tax = customer?.total_tax || 0

  vatConfig.tax_percent = (keepDecimals(customer?.items[0]?.tax_rate * 100, 2) as any) || 0

  let _currency = customer?.currency

  paymentLogReported(data)

  // 显示税率 vat
  if (vatList.includes(customer?.currency)) {
    vatValue.value = _isBackUnit
      ? useTranslateI18n("payment.vat", [`VAT ${vatConfig.tax}${_char}(${vatConfig.tax_percent}%)`])
      : useTranslateI18n("payment.vat", [`VAT ${_char}${vatConfig.tax}(${vatConfig.tax_percent}%)`])
    // gst
  } else if (gstList.includes(customer?.currency)) {
    vatValue.value = _isBackUnit
      ? useTranslateI18n("payment.vat", [`GST ${vatConfig.tax}${_char}(${vatConfig.tax_percent}%)`])
      : useTranslateI18n("payment.vat", [`GST ${_char}${vatConfig.tax}(${vatConfig.tax_percent}%)`])
  } else {
    vatValue.value = _isBackUnit
      ? `${useTranslateI18n("payment.comboSub.t79")} ${vatConfig.tax}${_char}(${vatConfig.tax_percent}%)`
      : `${useTranslateI18n("payment.comboSub.t79")} ${_char}${vatConfig.tax}(${vatConfig.tax_percent}%)`
  }

  // 填写税号 展示refund
  if (data.event === `Checkout.Vat.Applied`) {
    refundVatValue.value = _isBackUnit
      ? useTranslateI18n("payment.vat2", [
          `${useTranslateI18n("payment.comboSub.t79")} ${vatConfig.tax}${_char}(${vatConfig.tax_percent}%)`
        ])
      : useTranslateI18n("payment.vat2", [
          `${useTranslateI18n("payment.comboSub.t79")} ${_char}${vatConfig.tax}(${vatConfig.tax_percent}%)`
        ])

    refundResultValue.value = vatConfig.total - vatConfig.tax
  }

  vatConfig.taxAll = _isBackUnit ? `${vatConfig.tax}${_char}` : `${_char}${vatConfig.tax}`

  if (unref(isPaymentType)) {
    currency.value = _currency
    trueChar.value = _char
    // 单位放前面还是后面
    priceDetail.total = _isBackUnit
      ? `${useNumFormat(customer.total)}${_char}`
      : `${_char}${useNumFormat(customer.total)}`

    // 订阅的perMoney字段不变
    priceDetail.perMoney = unref(isPaymentType)
      ? priceDetail.perMoney
      : _isBackUnit
      ? `${keepDecimals(Number(customer.total / props.orderInfo.credits), 4)}${_char}`
      : `${_char}${keepDecimals(Number(customer.total / props.orderInfo.credits), 4)}`

    if (data.event === `Checkout.Coupon.Applied`) {
      priceDetail.discount = _isBackUnit
        ? `${useNumFormat((customer.unit - customer.total).toFixed(2))}${_char}`
        : `${_char}${useNumFormat((customer.unit - customer.total).toFixed(2))}`
    }

    // 删除coupon code
    if (data.event === "Checkout.Coupon.Remove") {
      priceDetail.discount = ""
    }
  }

  priceKey.value = priceKey.value + 1
}

// 记录用户支付记录
let paymentLogReportedObj = {}
async function paymentLogReported(data) {
  let resultParams = {} as IPaymentLogData
  if (data?.event) {
    resultParams.checkout_id = data?.eventData?.checkout?.id
    resultParams.email = data?.eventData?.user?.email
    resultParams.paddle_id = props.orderInfo.paddle_id
    resultParams.sale_gross = Number(data?.eventData?.checkout?.prices?.customer?.total)
    resultParams.sale_currency = data?.eventData?.checkout?.prices?.customer?.currency
    resultParams.payment_method = PaymentMethod.Card
  }
  let events = [`Checkout.Error`, `Checkout.Login`, `Checkout.Payment.Selected`]
  if (events.includes(data.event)) {
    switch (data.event) {
      case `Checkout.Error`:
        resultParams.error = data?.eventData?.error
        break
      case `Checkout.Login`:
        break
      case `Checkout.Payment.Selected`:
        resultParams.payment_method = PaymentMethod.PayPal
        break
    }
    if (!paymentLogReportedObj[data.event]) {
      paymentLogReportedObj[data.event] = true
      let timeId = setTimeout(async () => {
        await postPaymentLog({
          ...resultParams,
          custom_link_uuid: props.paddleNoLoginPassThroughUUid,
          trade_no: unref(_trade_no)
        })
        delete paymentLogReportedObj[data.event]
        clearTimeout(timeId)
      })
    }
  }
}

usePaddle({
  info: {
    product_id: product_id.value,
    frameInitialHeight: frameInitialHeight.value,
    userInfo_email: userInfo_email.value
  },
  checkout_url: props.checkout_url,
  isPassThrough: props.isPassThrough,
  isFreeTrial: isFreeTrial.value,
  throughFn: getThrough,
  eventCallback,
  successCallback,
  closeCallback
})

const dateStr = computed(() => {
  // const date = new Date()
  // const year = date.getFullYear()
  // const month = date.getMonth()
  // const day = date.getDate()
  // const mon_arr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec"]
  let nextDate = new Date()
  nextDate.setFullYear(nextDate.getFullYear() + 1)
  return [getLocalDateString(new Date(), "YYYY/MM/DD"), getLocalDateString(nextDate, "YYYY/MM/DD")]
})

const periodDate = computed(() => {
  if (props?.orderInfo?.period_start && props?.orderInfo?.period_end) {
    return `${getLocalDateString(props?.orderInfo?.period_start, "YYYY/MM/DD")}-${getLocalDateString(
      props?.orderInfo?.period_end,
      "YYYY/MM/DD"
    )}`
  } else {
    return `${unref(dateStr)[0]} - ${unref(dateStr)[1]}`
  }
})

const endDate = computed(() => {
  if (props?.orderInfo?.period_start && props?.orderInfo?.period_end) {
    return `${getLocalDateString(props?.orderInfo?.period_end, "YYYY/MM/DD")}`
  } else {
    return `${unref(dateStr)[1]}`
  }
})
</script>
<style>
#paddel {
  background: transparent !important;
}
</style>

<style scoped lang="scss">
.content-wrapper {
  display: flex;
  justify-content: center;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}
/* .paddel {
  height: 375px;
  width: 520px;
} */
.price {
  width: 410px;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 40px;
  .p-t {
    padding-bottom: 8px;
    font-size: 14px;
    line-height: normal;
    .tip-r-email {
      @include fontRobotoBold;
    }
    .referral-bonus {
      border-radius: 6px;
      background: #fff7e8;
      padding: 6px 10px;
      margin-top: 8px;
      color: #d25f00;
      font-size: 12px;
      @include fontRobotoRegular;
    }

    .tip {
      &.t-item {
        color: #000;
      }
      &.tip-f-t {
        color: #0000008c;
      }
    }
    .title-time {
      margin-top: 24px;
      :deep(.title-time-b) {
        @include fontRobotoBold;
      }
    }
    .title {
      font-size: 24px;
      line-height: 29px;
      margin-top: 4px;
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .free-flag {
        font-size: 11px;
        @include fontRobotoRegular;
        line-height: normal;
        padding: 4px 6px;
        border-radius: 6px;
        background: #ffe050;
        margin-left: 8px;
      }
    }
    .sub-list {
      display: flex;
      flex-direction: column;
      &-item {
        font-size: 14px;
        line-height: 22px;
        color: #000000d9;
        margin-bottom: 20px;
        display: flex;
        &::before {
          content: "";
          margin-top: 5px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.55);
          display: inline-block;
          margin-right: 8px;
          flex-shrink: 0;
        }
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
        :deep(.paddle-payment-sub-b) {
          color: rgba(0, 0, 0, 0.85);
          @include fontMedium;
          .paddle-payment-sub-click {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        :deep(.paddle-payment-sub-b-2) {
          color: rgba(0, 0, 0, 0.55);
          @include fontSemibold;
          .paddle-payment-sub-click {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        :deep(.paddle-payment-sub-b-t1) {
          text-decoration: underline;
          cursor: pointer;
        }
        :deep(.paddle-payment-sub-b-t2) {
          text-decoration: underline;
          cursor: pointer;
        }
        :deep(.paddle-payment-sub-b-t3) {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .bottom-tip-w {
    padding-top: 8px;
    padding-bottom: 12px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    &-t {
      color: #000000d9;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .tip {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    margin-top: 10px;

    .tip-r {
      .tip-r-per {
        @include fontSemibold;
        font-size: 16px;
        line-height: 19px;
      }
      .tip-r-unit {
        font-size: 14px;
        color: #0000008c;
      }
    }
    .tip-tt {
      @include fontSemibold;
      font-size: 16px;
      line-height: 19px;
      &.tip-tt-num {
        color: #d25f00;
      }
    }
    &.tip-sub {
      .tip-tt {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .justify {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &.discount {
      > span {
        color: #1cb95a;
        font-size: 16px;
      }
    }
  }
  .save-sub {
    padding: 10px;
    border-radius: 6px;
    background-color: #fff7e8;
    color: #d25f00;
    line-height: normal;
    @include fontMedium;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }

  .save-sub-discount {
    .one-c {
      .num {
        color: #d25f00;
        font-size: 16px;
        line-height: 19px;
        @include fontSemibold;
      }
    }
    .total-c {
      padding: 4px;
      border-radius: 6px;
      background: #fff7e8;
      display: flex;
      align-items: center;
      .total-c-co {
        font-size: 12px;
        color: #d25f00;
        font-family: "Roboto-Medium";
        margin: 0 4px;
      }
      .total-c-cl {
        cursor: pointer;
      }
    }
  }

  .save-sub-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &:nth-child(2) {
      margin-top: 4px;
    }
  }

  .save-sub-desc {
    display: flex;
    justify-content: space-between;
    @include fontRegular;
    position: relative;
    &-date {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.55);
    }
    &-price {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.55);
    }
  }

  .flexEnd {
    display: flex;
    justify-content: flex-end;
  }
  .van {
    margin-top: 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
  }
  .title {
    @include fontSemibold;
    font-size: 24px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  .line {
    width: 100%;
    height: 0.5px;
    border: 0.5px solid rgba(0, 0, 0, 0.15);
    margin: 17px 0;
  }
  .bottom-tip {
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
  }
  .bottom-tip-bl {
    color: #000;
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
  }
  // &::before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   height: 100%;
  //   width: 1px;
  //   background: white;
  // }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   top: 5%;
  //   height: 90%;
  //   width: 1px;
  //   background: rgba(0, 0, 0, 0.15);
  // }
}

.save-card-price {
  .tip {
    .tip-tt {
      color: #000;
    }
  }
}
</style>

<style lang="scss">
.welcome-page-dialog {
  &.payment-paddle_dialog {
    @include device-max-width-1400 {
      .content {
        width: 80%;
        .content-d {
          .content-wrapper {
            width: 100%;
            #paddel {
              width: 60%;
              height: auto !important;
              iframe {
                width: 100% !important;
              }
            }
            .price {
              flex: 1;
              width: auto;
              height: auto !important;
              .line {
                width: 100%;
              }
            }
          }
        }
      }
    }
    @include device-max-width-600 {
      .content {
        width: 75%;
        .close-icon {
          right: -32px;
        }
        .content-d {
          .content-wrapper {
            flex-direction: column;
            #paddel {
              width: 100% !important;
              display: flex;
            }
            .price {
              padding-left: 0;
              border-left: 0;
              &::before {
                display: none;
              }
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
.paddleDialog-to_details {
  color: rgba(0, 0, 0, 0.55);
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #1877f2 !important;
  }
}
</style>
