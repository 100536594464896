import { ILocaleValue } from "@/constant/i18n-country"

let script: HTMLScriptElement
const vendor_id = ref(153193) //正式

const vendor_sandbox_id = ref(6691)

interface IProductInfo {
  product_id: any
  userInfo_email: string
  frameInitialHeight: number
}

interface IUsePaddleOptions {
  isFreeTrial?: boolean
  info: IProductInfo
  isPassThrough: boolean
  checkout_url: string
  throughFn: (...args: any[]) => any
  eventCallback: (...args: any[]) => any
  successCallback: (...args: any[]) => any
  closeCallback: (...args: any[]) => any
}

export default function usePaddle(options: IUsePaddleOptions) {
  const openPaddle = async () => {
    // const product_id = ref(786601) //正式
    let passThrough
    if (options.isPassThrough) {
      passThrough = await options.throughFn()
    }
    ;(window as any).Paddle.Checkout.open({
      override: options.checkout_url,
      product: options.info.product_id, //此结帐所针对的 Paddle 产品、订阅或捆绑包的 ID。
      title: "", //覆盖结帐页面上的产品名称。
      message: "", //覆盖结帐时产品名称下显示的消息/文本字符串。
      email: options.info.userInfo_email,
      // country: "HK",
      success: "", //成功后的重定向地址
      method: "inline", //指定结帐应使用什么显示模式来呈现用户界面inline,overlay,sdk
      // allowQuantity: false, //在结帐页面上启用数量选择器。
      passthrough: passThrough, //用户信息
      disableLogout: true, //禁用用户在结帐时注销/更改电子邮件的功能。
      frameTarget: "paddel", // div的类名
      locale: useI18nCountry().value || ILocaleValue.en, //结账时的语言环境
      frameInitialHeight: options.info.frameInitialHeight, //加载时div的高度
      frameStyle: "width:520px; padding: 0px;margin:0;background-color: white;border: none;",
      successCallback: options.successCallback,
      closeCallback: options.closeCallback
    })
  }

  const paddleRegistry = () => {
    // 开启内置沙盒环境
    if (window._ts_sandbox_) {
      ;(window as any).Paddle.Environment.set("sandbox")
      ;(window as any).Paddle.Setup({ vendor: vendor_sandbox_id.value, eventCallback: options.eventCallback })
    } else {
      ;(window as any).Paddle.Setup({ vendor: vendor_id.value, eventCallback: options.eventCallback })
    }

    openPaddle()
  }

  const addPaddelScript = () => {
    if (document.querySelector("#e_paddle_sdk")) {
      document.body.removeChild(document.querySelector("#e_paddle_sdk"))
    }
    script = document.createElement("script")
    script.id = "e_paddle_sdk"
    script.type = "text/javaScript"
    script.src = "https://cdn.paddle.com/paddle/paddle.js"
    document.body.appendChild(script)
    // 脚本加载完成注册 paddle
    script.addEventListener(
      "load",
      () => {
        paddleRegistry()
      },
      { passive: true }
    )
    script.addEventListener(
      "error",
      () => {
        console.error("paddle sdk error~")
      },
      { passive: true }
    )
  }

  onBeforeMount(() => {
    addPaddelScript()
  })
}
