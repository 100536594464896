<template>
  <div class="paddle-not-valid">
    <div class="paddle-not-valid-title">
      {{ useTranslateI18n("payment.dialog.t1") }}
    </div>
    <div class="paddle-not-valid-content" v-html="useTranslateI18n('payment.dialog.t2')"></div>
    <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="contactUsClick"
      ><CommonAnimationScrollText>{{ useTranslateI18n("payment.dialog.t3") }}</CommonAnimationScrollText></CommonButton
    >
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    callback: () => void
  }>(),
  {
    callback: () => {}
  }
)

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Roboto-Medium"
})
const contactUsClick = useDebounceFn(() => {
  props.callback()
}, 300)
</script>

<style scoped lang="scss">
.paddle-not-valid {
  line-height: normal;
  &-title {
    font-size: 24px;
    @include fontRobotoBold;
    color: #000;
  }
  &-content {
    margin-top: 24px;
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
    line-height: 22px; /* 157.143% */
  }
  .submit-button {
    margin-top: 40px;
  }
}
</style>
