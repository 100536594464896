import PaymentVnDialog from "@/components/pages/payment/PaymentVnDialog.vue"
import { createVNode, render, VNode } from "vue"
import { IOrderInfo } from "@/types/components/pages/payment/paymentVnDialog"
import { IUserPaymentMethodObj } from "@/composables/api/combo"

let openDialog = false

export const useSelectPaymentDialog = (options: {
  show_7day_free_tips?: boolean
  show_referral_code?: boolean
  referral_code?: string
  coupon_code?: string
  customLinkUuid?: any
  topTip: string
  showComboDesc?: boolean
  showAfterSubDesc?: boolean
  orderInfo?: IOrderInfo
  current_plan?: IOrderInfo
  compare_state?: INewCompareState
  radioSelectList?: IUserPaymentMethodObj[]
  isBackUnit?: boolean | string
  showAddDiscount?: boolean
  bottomDescSlot?: (...args: any[]) => VNode
  callback?: (...args: any[]) => any
}) => {
  if (openDialog) return
  const {
    orderInfo,
    radioSelectList,
    isBackUnit,
    callback,
    showAfterSubDesc,
    showComboDesc,
    topTip,
    showAddDiscount,
    customLinkUuid,
    coupon_code,
    current_plan,
    compare_state,
    show_referral_code,
    referral_code,
    show_7day_free_tips
  } = options
  const vm = createVNode(
    PaymentVnDialog,
    {
      show_7day_free_tips,
      show_referral_code,
      referral_code,
      coupon_code,
      customLinkUuid,
      topTip,
      showComboDesc,
      showAfterSubDesc,
      orderInfo,
      current_plan,
      compare_state,
      radioSelectList,
      isBackUnit,
      showAddDiscount,
      callback,
      closeEmit() {
        openDialog = false
      }
    },
    {
      bottomDesc: options.bottomDescSlot
    }
  )
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
  openDialog = true
}
